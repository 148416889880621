<template>
    <div class="bg-white dark:bg-black">
        <!-- Mobile menu -->
        <TransitionRoot as="template" :show="open">
            <Dialog as="div" class="relative z-40 lg:hidden" @close="open = false">
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
                                 enter-from="opacity-0" enter-to="opacity-100"
                                 leave="transition-opacity ease-linear duration-300"
                                 leave-from="opacity-100" leave-to="opacity-0">
                    <div class="fixed inset-0 bg-black bg-opacity-25"/>
                </TransitionChild>

                <div class="fixed inset-0 z-40 flex">
                    <TransitionChild as="template"
                                     enter="transition ease-in-out duration-300 transform"
                                     enter-from="-translate-x-full" enter-to="translate-x-0"
                                     leave="transition ease-in-out duration-300 transform"
                                     leave-from="translate-x-0" leave-to="-translate-x-full">
                        <DialogPanel
                            class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-6 shadow-xl">
                            <div class="flex px-4 pt-5 pb-2">
                                <button type="button"
                                        class="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                                        @click.passive="open = false">
                                    <span class="sr-only">Sluit menu</span>
                                    <CustomSvg :name="'Xmark'" aria-hidden="true"></CustomSvg>
                                </button>
                            </div>

                            <!-- Links MOBIEL-->
                            <HeaderMobileLeft :navigation="navigation" :website="website" :menu_items="menu_items" :page="page" :userTheme="userTheme"
                                              @headerSearch="headerSearch"
                                              @switchTheme="toggleTheme">></HeaderMobileLeft>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>

        <header class="relative bg-white dark:bg-black">
<!--            <p class="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8-->
<!--            dark:bg-blue-900-->
<!--" v-if="website.header_text">{{website.header_text}}</p>-->

            <nav aria-label="Top" class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div class="border-b-0 md:border-b"><!-- border-b border-gray-200 dark:border-gray-600-->
                    <div class="flex h-14 items-center ">
                        <button type="button"
                                class="rounded-md bg-white p-0 pr-2 text-gray-400 lg:hidden dark:bg-blue-600"
                                @click.passive="open = true">
                            <span class="sr-only">Open menu</span>
                            <CustomSvg :name="'bars3'" aria-hidden="true"></CustomSvg>
                        </button>

                        <!-- Logo -->
                        <div class="ml-0 sm:ml-4 flex lg:ml-0 ">
                            <jet-nav-link href="/">
                                <span class="sr-only">Logo {{website.name}}</span>
                                <img class="h-8 w-auto" :class="userTheme==='dark'? 'invert': ''" :alt="website.logo"
                                     :src="asset(website.logo)" :height="website.logo_height" :width="website.logo_width"/>
                            </jet-nav-link>
                        </div>

                        <!-- Flyout menus -->
                        <PopoverGroup class="hidden lg:ml-8 lg:block lg:self-stretch  w-64">
                            <div class="flex h-full space-x-8">


                                <Popover v-for="category in navigation.categories"
                                         :key="category.name" class="flex" v-slot="{ open }">
                                    <div class="relative flex">
                                        <PopoverButton
                                            :class="[open ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-700 hover:text-gray-800', 'relative z-10 -mb-px flex items-center ' +
                                             'dark:text-white dark:hover:text-gray-300 border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out']">
                                            {{ category.name }}
                                        </PopoverButton>
                                    </div>

                                    <transition
                                        enter-active-class="transition ease-out duration-200"
                                        enter-from-class="opacity-0" enter-to-class="opacity-100"
                                        leave-active-class="transition ease-in duration-150"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <PopoverPanel
                                            class="absolute inset-x-0 top-full text-sm text-gray-500 z-50">
                                            <!-- Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow -->
                                            <div
                                                class="absolute inset-0 top-1/2 bg-white shadow z-50"
                                                aria-hidden="true"/>

                                            <div class="relative bg-white dark:bg-gray-800 z-50 text-gray-900 dark:text-white dark:hover:text-gray-300">
                                                <div class="mx-auto max-w-7xl px-8">
                                                    <div
                                                        class="grid grid-cols-2 gap-y-10 gap-x-8 py-16">

                                                        <div
                                                            class="col-start-2 grid grid-cols-2 gap-x-8">

                                                            <div v-for="item in menu_items.featured"
                                                                 :key="item.name"
                                                                 class="group relative text-base sm:text-sm">
                                                                <div
                                                                    class="aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                                                    <img :src="asset(item.imageSrc)"
                                                                         :alt="item.imageAlt"
                                                                         class="object-cover object-center"/>
                                                                </div>
                                                                <jet-nav-link :href="item.href"
                                                                   class="mt-6 block font-medium dark:text-white">
                                                                    <span
                                                                        class="absolute inset-0 z-10"
                                                                        aria-hidden="true"/>
                                                                    {{ item.name }}
                                                                </jet-nav-link>
                                                                <p aria-hidden="true" class="mt-1">
                                                                    Koop nu</p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="row-start-1 grid grid-cols-3 text-sm">
                                                            <div class="col-span-2">
                                                                <div
                                                                    class="row-start-1 grid grid-cols-2 gap-y-2 gap-x-8 text-sm">
                                                                    <h5 class="font-bold text-black dark:text-white">
                                                                        Top Categorieën</h5>
                                                                    <div class="clear-both"></div>
                                                                    <div
                                                                        v-for="(category,key) in website.categories"
                                                                        :key="category.url">
                                                                        <jet-nav-link :href="'/'+key" :id="`${category.name}-heading`"
                                                                                      class="font-medium text-gray-900 dark:text-white">
                                                                            {{
                                                                                category.name
                                                                            }}
                                                                        </jet-nav-link>
                                                                    </div>
                                                                </div>
<!--                                                                <div class="mt-10">-->

<!--                                                                    <label class="inline-flex relative items-center cursor-pointer">-->
<!--                                                                        <input type="checkbox" value="" class="sr-only peer"    @change="toggleTheme"-->
<!--                                                                               :checked="userTheme==='dark'">-->
<!--                                                                        <div class="w-12 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4-->
<!--                                                                         peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700-->
<!--                                                                         peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']-->
<!--                                                                         after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300-->
<!--                                                                          after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>-->
<!--                                                                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" v-if="userTheme!=='dark'">🌙</span>-->
<!--                                                                        <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300" v-if="userTheme!=='light'">☀</span>-->

<!--                                                                    </label>-->
<!--                                                                </div>-->
                                                            </div>

                                                            <div class="col-span-1">
                                                                <div
                                                                    class="row-start-1 grid grid-cols-1 gap-y-2 gap-x-8 text-sm">
                                                                    <h5 class="font-bold text-black dark:text-white">
                                                                        Top Merken</h5>
                                                                    <div class="clear-both"></div>

                                                                    <jet-nav-link
                                                                        v-for="(brand,url) in website.brands" :key="category.url" :href="('/'+website.product+'/merk/'+url)">
                                                                        <p :id="`${url}-heading`" class="font-medium text-gray-900 dark:text-white">{{ brand }}</p>
                                                                    </jet-nav-link>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                                <jet-nav-link v-for="page in menu_items.header_right" :key="page.id"
                                              :href="'/'+page.url"
                                              class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-white dark:hover:text-gray-300">
                                    {{
                                        page.name
                                    }}
                                </jet-nav-link>
                            </div>
                        </PopoverGroup>

                        <div class="ml-auto flex  flex-row-reverse md:items-center md:flex-row pl-2 w-1/3 sm:w-1/2 md:w-1/2 sm:pl-2 h-10 ">

                            <div class=" lg:ml-6 h-10 hidden md:block grow ">
                                <HeaderSearch :website="website" :page="page" @headerSearch="headerSearch"></HeaderSearch>
                            </div>

                            <!-- Search -->
                            <div class=" lg:ml-6 h-10 md:mt-0 ml-3  ">

                                <!-- favoriet -->
                                <Popover v-slot="{ open }">
                                    <popoverButton class="outline-none" ref="button" >
                                        <div class=" p-0 mt-2 rounded ml-2 float-right border-0 active:border-white focus:border-white">
                                        <CustomSvg name="favourite-true"  customClass="fill-blue-800 h-2 m-1" ></CustomSvg>
                                        <span class="absolute flex -mt-2 -ml-2 h-5 w-5 bg-sky-500 rounded-full content-center flex justify-center">
                                          <span class="text-white text-sm flex content-center " v-if="website.favorite_variants!==undefined">
                                              {{Object.keys(website.favorite_variants).length}}
                                          </span>
                                    </span>
                                    </div>
                                    </popoverButton>
                                    <transition
                                        enter-active-class="transition ease-out duration-200"
                                        enter-from-class="opacity-0" enter-to-class="opacity-100"
                                        leave-active-class="transition ease-in duration-150"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <PopoverPanel
                                            class="absolute inset-y-0 right-0 top-full text-sm text-gray-500 z-100  w-full ">
                                            <div class="mx-auto max-w-7xl px-2  mt-4 h-full sm:px-6 lg:px-8 ">
                                                <div class=" bg-white border rounded shadow-2xl">



                                                <div class="grid grid-cols-2 md:grid-cols-4 gap-4 px-1 p-2 m-3 ">

                                                    <div class="col-span-2 md:col-span-4 flex flex-wrap sm:flex-row">
                                                        <div class="flex w-full">
                                                            <h3 class="mt-0 text-black grow  px-2 grow">Je favoriete producten</h3>

                                                            <div class="float-right grow ">

                                                                <popoverButton class="right-0 relative float-right mt-1">
                                                                    <CustomSvg name="Xmark"  customClass="fill-blue-800 h-2" ></CustomSvg>
                                                                </popoverButton>

                                                                <div class="hidden sm:block float-right pr-4">
                                                                    <div class="float-left  text-black flex justify-center mr-3 w-full" v-if="website.favorite_url!==undefined">
                                                                        <input :value="website.favorite_url ?? ''" ref="myinput" class=" grow border rounded-l  p-2">
                                                                        <div class="bg-blue-600 text-white rounded-r pt-2 p-2 cursor-pointer"  @click="copy(website.favorite_url)">Delen</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="float-right grow  mt-4">
                                                        <div class="grow float-right sm:hidden text-black flex flex-row" v-if="website.favorite_url!==undefined">
                                                            <input :value="website.favorite_url ?? ''" ref="myinput" class="border rounded-l p-2">
                                                            <div class="bg-blue-600 text-white rounded-r pt-2 p-2 cursor-pointer"  @click="copy(website.favorite_url)">Delen</div>
                                                        </div>
                                                        </div>

                                                    </div>

                                                    <div class="col-span-2 px-2 mt-4 w-full" v-if="website.favorite_variants.length < 1">
                                                        <div class="float-left">U heeft nog geen favoriete producten. Gebruik de</div>
                                                        <div class="inline"><CustomSvg name="favourite-false" customClass="fill-blue-800 !relative mx-2 !w-3.5 mt-0.5"></CustomSvg></div>
                                                        <div class="float-left">om producten te onthouden.</div>
                                                    </div>
                                                    <FavouriteProducts :website="website"></FavouriteProducts>

                                                </div>
                                                </div>
                                            </div>
                                        </PopoverPanel>
                                    </transition>
                                </Popover>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="block md:hidden">
                <HeaderSearch :website="website" :page="page" @headerSearch="headerSearch"></HeaderSearch>
                </div>
            </nav>
        </header>


    </div>
</template>

<script>
import {ref} from 'vue'
import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'
import JetNavLink from '@/Jetstream/NavLink.vue'
import HeaderMobileLeft from "@/Pages/Admin/Page/Templates/Partials/HeaderMobileLeft.vue";
import HeaderSearch from  "@/Pages/Admin/Page/Templates/Partials/HeaderSearch.vue";
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
import axios from "axios";
import FavouriteProducts from "@/Pages/Admin/Page/Templates/Partials/FavouriteProducts.vue";
import Input from "@/Jetstream/Input.vue";

export default {
    components: {
        Input,
        FavouriteProducts,
        HeaderMobileLeft,
        JetNavLink,
        Dialog,
        DialogPanel,
        Popover,
        PopoverButton,
        PopoverGroup,
        PopoverPanel,
        Tab,
        TabGroup,
        TabList,
        TabPanel,
        TabPanels,
        TransitionChild,
        TransitionRoot,
        HeaderSearch,
        CustomSvg
    },
    props: {
        menu_items: Object,

        page: Object,
        editable: Boolean,
        website: Object,
        search_query:String,

    },
    data() {
        return {
            open: ref(false),
            navigation: {
                categories: [
                    {
                        id: 'products',
                        name: 'Ons Assortiment',
                    },
                ],
            },
            favorite_variants:[],
            userTheme: "light-theme",
            favourites:[],

        }

    },

    methods: {
        headerSearch(search){
            this.$emit('headerSearch',search);
        },
        toggleTheme() {
            const activeTheme = localStorage.getItem("user-theme");
            if (activeTheme === "light") {
                this.setTheme("dark");
            } else {
                this.setTheme("light");
            }
        },
        setTheme(theme) {
            localStorage.setItem("user-theme", theme);
            this.userTheme = theme;
            document.documentElement.className = theme;
        },
        fallbackCopyTextToClipboard(text){
            var textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);

            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        },
        copy(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                this.$refs.button.$el.click();
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
getMediaPreference() {
            //disable dark
            // if( window && typeof window !== 'undefined') {
            //     const hasDarkPreference = window.matchMedia(
            //         "(prefers-color-scheme: dark)"
            //     ).matches;
            //     if ( window && typeof window !== 'undefined' && hasDarkPreference!==undefined) {
            //         return "dark";
            //     } else {
            //         return "light";
            //     }
            // }
            return "light";
        },
        getFavourites() {
            let cookie = this.getCookie('favourite');
            this.favourites = axios.post(route('favourite.products'), {
                cookie: cookie,
            }).then((response) => {
                this.website.favorite_variants= response.data.favourites;
                this.website.favorite_url =  response.data.favourite_url;
            });
        },
        getTheme() {
            return localStorage.getItem("user-theme");
        },


    },
    mounted() {
        //disable dark
       // const initUserTheme = this.getTheme() || this.getMediaPreference();
       // console.log(initUserTheme);
      //  this.setTheme(initUserTheme);
        this.getFavourites();
        this.setTheme('light');
    },
}


</script>
